.bpo-page {
    h2 {
        color: white;
        font-size: 51px;
    }
    p {
        color: white;
        font-size: 19px;
    }
    .start-btn {
        background-color: rgb(230, 110, 49);
        color: white;
        border: none;
        outline: none;
        border-radius: 10px;
        cursor: pointer;
        padding: 7px 18px;
    }
}
