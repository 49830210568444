.mobile-service {
    .serives-content {
        background-color: #322c2c;
        height: 229px;
        cursor: pointer;
        padding: 12px;
        border-radius: 13px;
        transition: 0.3s ease-in-out;
        .for-img {
            background-color: white;
            display: flex;
            padding: 10px;
            width: fit-content;
            border-radius: 8px;
        }
        h4 {
            color: white;
            text-transform: uppercase;
            font-weight: 700;
        }
        p {
            color: white;
            margin: 0;
            font-size: 25px;
            font-weight: 700;
        }
        span {
            color: white;
        }
        &:hover {
            background-color: #e66e31;
        }

        @media screen and (max-width: 576px) {
            height: 251px;
        }
        @media only screen and (min-width: 576px) and (max-width: 768px) {
            height: 339px;
        }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            height: 289px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1440px) {
            height: 280px;
        }
    }
}
