.tech-stack {
    h2 {
        color: white;
        text-align: center;
    }
    .nav-tabs {
        justify-content: center;
        border: none;
    }
    .nav-link {
        color: rgb(230, 110, 49);
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
        background-color: white;
        color: black;
    }
    .img-div {
        background-color: white;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 83px;
        width: 82px;
    }
    h6 {
        color: white;
    }
}
