.conatct-form {
    background-color: #1b1818;
    padding: 15px;
    border-radius: 13px;
    .stylish {
        font-size: 17px;
        font-weight: 700;
        margin: 0;
    }
    h5 {
        font-size: 35px;
        font-weight: 700;
        color: black;
        letter-spacing: 1px;
        margin-top: 7px;
    }

    .form-control {
        background: #737c7617;
        color: white;
        font-size: 13px;
        opacity: 0.8;
        font-weight: 700;
        padding: 15px 20px;
        border: none;
    }
    .form-control:focus {
        box-shadow: none;
        border: none;
    }
    .form-control::placeholder {
        color: white;
        font-weight: 600;
    }

    .send-btn {
        border: none;
        outline: none;
        color: white;
        padding: 8px 40px;
        background-color: rgb(103 96 93);
    }
}
