.contact-us-section {
    .for-bg {
        background-image: url('../../assets/images/contact.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            color: white;
        }
        p {
            color: white;
        }
    }
}
