.portfolio-bc {
    .section-img {
        position: relative;
        display: flex;
        align-items: end;
        cursor: pointer;
        transition: 0.5s ease-in-out;

        img {
            width: 100%;
            height: 441px;
            object-fit: cover;
        }

        .show-hover {
            position: absolute;
            display: block;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            padding: 10px 20px;
            background-color: #322c2c;
            border-radius: 14px 14px 0 0;

            h5 {
                color: white;
            }

            p {
                color: white;
            }
            .hover-btn {
                button {
                    background-color: rgb(230, 110, 49);
                    color: white;
                    border: none;
                    outline: none;
                    border-radius: 10px;
                    cursor: pointer;
                    padding: 7px 18px;
                }
            }
        }

        &:hover {
            .show-hover {
                opacity: 1;
            }
            border-radius: 14px;
        }
    }
    .explore-btn {
        border: none;
        outline: none;
        background: white;
        color: black;
        padding: 11px 20px;
        border-radius: 10px;
        font-size: 17px;
        font-weight: 500;
        transition: 0.5s ease-in-out;
        &:hover {
            background-color: rgb(230, 110, 49);
            color: white;
        }
    }
}
