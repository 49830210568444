.footer {
    background-color: #1b1818;

    p {
        color: white;
    }
    h6 {
        color: white;
    }
    .for-link {
        list-style-type: none;
        padding: 17px 26px;
        border-radius: 15px;

        h5 {
            color: white;
        }
        li {
            color: white;
            transition: 0.3s ease-in-out;
            cursor: pointer;
            margin-top: 5px;
            &:hover {
                color: rgb(230, 110, 49);
            }
        }
    }
    .social-icon {
        border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
        img {
            filter: brightness(66);
        }
        
    }

     .social-icon:hover {
        background-color:  rgb(230, 110, 49) !important ;/* Change the color to orange on hover */
    }
  
    .active-social-icons {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: rgb(230, 110, 49);
        margin-left: 8px;
        margin-right: 8px;
        img {
            filter: brightness(0);
        }
    }

  

    .for-contacts {
        display: flex;
        .phone {
            background-color: rgb(230, 110, 49);
            border-radius: 50%;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 20px;
            }
        }
    }

    .footer-end {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        p {
            color: white;
        }
    }
}
