.mobile-process {
    h4 {
        color: white;
    }
    .for-content {
        background-color: #322c2c;
        padding: 10px;
        border-radius: 10px;
    }
}
