.touch-text {
    color: white;
    font-size: 25px;
}
.contact-section {
    margin: 0 25px;
    background: #2e3b45;
    padding: 10px;
    border-radius: 16px;
    p {
        color: white;
        margin: 0;
    }
    h2 {
        color: white;
    }
    h4 {
        color: white;
    }
    h6 {
        color: white;
    }

    .for-contacts {
        display: flex;
        .phone {
            background-color: #1b1818;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 20px;
            }
        }
    }
}
